const preparedProgress = (isCompleted?: boolean, progressData?: { goal: number; complete: number }) => {
	if (isCompleted) {
		return "100%";
	}
	if (progressData) {
		return `${(100 / progressData.goal) * progressData.complete}%`;
	}

	return "12px";
};

export default preparedProgress;
